<template>
  <BaseCard title="Detail Faktur Penjualan">
    <template #action>
      <div v-if="getOrder.data.attributes.cancel_warehouse_description">
        <div
          class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
        >
          Dibatalkan Gudang
        </div>
        <div class="mt-2 text-right text-xs">
          {{ getOrder.data.attributes.cancel_warehouse_description }}
        </div>
      </div>
    </template>
    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <base-input
          v-model="getOrder.data.attributes.origin_code"
          :disabled="disabled"
          inset
          ref="focusNoPenjualan"
          label="No. Faktur"
          type="text"
          name="no_penjualan"
          id="no_penjualan"
          placeholder="Masukkan No Penjualan yang akan dibayarkan"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          :value="destinationOffice?.attributes?.code"
          :disabled="disabled"
          readonly
          inset
          label="Kode Stockist"
          type="text"
          name="kode_stockist"
          id="kode_stockist"
          placeholder="ex: SCR338"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          :value="destinationOffice?.attributes?.name"
          :disabled="disabled"
          readonly
          inset
          label="Nama Pemilik"
          type="text"
          name="nama_pemilik"
          id="nama_pemilik"
          placeholder="ex: AYATULLOH MUHAMMAD DEVA"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          :value="originWarehouse?.attributes?.code"
          :disabled="disabled"
          readonly
          inset
          label="Kode Gudang"
          type="text"
          name="kode_gudang"
          id="kode_gudang"
          placeholder="ex: GD123"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          v-model="getOrder.data.attributes.area_code"
          inset
          readonly
          label="Wilayah Harga"
          :disabled="disabled"
          name="wilayah_harga"
          id="wilayah_harga"
          placeholder="ex: Wilayah 1"
        />
      </div>
      <div v-if="withSaldo" class="sm:col-span-6">
        <base-input
          label="Saldo Stockist"
          inset
          :disabled="disabled"
          :value="destinationOffice?.attributes?.order_balance | toCurrency"
          readonly
          type="text"
          name="saldo_awal"
          id="saldo_awal"
          placeholder="ex: 15.000.000"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          :value="getOrder.data.attributes.grand_total_price | toCurrency"
          :disabled="disabled"
          readonly
          inset
          label="Total Penjualan"
          name="total_penjualan"
          id="total_penjualan"
          placeholder="ex: 5.000.000"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          :value="
            getOrder.data.attributes.order_shipment == 'delivery'
              ? 'Dikirim'
              : 'Diambil'
          "
          label="Metode Pengiriman"
          readonly
          inset
          :disabled="disabled"
          name="total_penjualan"
          id="total_penjualan"
          placeholder="ex: 5.000.000"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OrderInformation',
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    withSaldo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
    }),
    destinationOffice() {
      return this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['destination-office'].data.id
      );
    },
    originOffice() {
      return this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['origin-office'].data.id
      );
    },
    originWarehouse() {
      return this.getRelationships(
        this.getOrder,
        this.getOrder.data.relationships['origin-warehouse'].data.id
      );
    },
  },
  components: {
    // Your child components here
  },
  methods: {
    getRelationships(resource, id) {
      let data = resource.included.find(function (el) {
        return el.id === id;
      });
      return data;
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
