<script>
import dayjs from 'dayjs';
import BaseInput from '@/components/base/BaseInput.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentDetailRow',
  components: {
    BaseInput,
  },
  data() {
    return {
      errors: {
        note: null,
        transfer_date: null,
      },
      forms: {
        note: null,
        transfer_date: null,
      },
    };
  },
  props: {
    paymentMethod: Object,
    included: Array,
    invoice: {
      required: false,
    },
    withVerification: {
      type: Boolean,
      default: false,
    },
    paymentAmountAlignRight: {
      type: Boolean,
      default: true,
    },
    withNote: {
      type: Boolean,
      default: false,
    },
    withDate: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/getUser',
    }),
    paymentMethodName: function () {
      if (this.paymentMethod.attributes.balance_used === 'order_balance') {
        return 'Saldo Pembayaran';
      }

      if (this.paymentMethod.attributes.balance_used === 'cashback') {
        return 'Cashback';
      }

      const paymentMethodUsingBalanceLists = ['stockist', 'order'];
      if (
        paymentMethodUsingBalanceLists.includes(
          this.paymentMethod.attributes.balance_used
        )
      ) {
        return this.paymentMethod.attributes.balance_used === 'stockist'
          ? 'Saldo Stockist'
          : 'Saldo Pembayaran';
      }

      if (!this.included) {
        return '-';
      }

      const bankName =
        this.getRelationships(
          this.included,
          this.paymentMethod.relationships['office-bank']?.data?.id
        )?.attributes.bank_name ?? '';

      if (this.paymentMethod.attributes.balance_used === 'edc') {
        return `EDC - ${bankName}`;
      }

      return bankName;
    },
    getTransferDate: function () {
      return this.paymentMethod.attributes.transfer_date;
    },
    isPaymentKp: function () {
      return (
        this.paymentMethod.attributes.origin_office_type === 'kantor_pelayanan'
      );
    },
    isTransferBank: function () {
      return (
        this.paymentMethod.attributes.payment_method_type === 'Transfer Bank'
      );
    },
    canVerify() {
      if (this.paymentMethod.attributes.is_verified) {
        return false;
      }

      if (
        this.isPaymentKp &&
        this.isTransferBank &&
        this.getUser.office_type !== 'pusat'
      ) {
        return false;
      }

      if (
        this.isPaymentKp &&
        !this.isTransferBank &&
        this.getUser.office_type !== 'kantor_pelayanan'
      ) {
        return false;
      }

      return true;
    },
    cannotVerifyMessage() {
      if (this.paymentMethod.attributes.is_verified) {
        return 'Pembayaran sudah terverifikasi';
      }

      if (
        this.isPaymentKp &&
        this.isTransferBank &&
        this.getUser.office_type !== 'pusat'
      ) {
        return 'Pembayaran transfer kp dilakukan oleh pusat';
      }

      if (
        this.isPaymentKp &&
        !this.isTransferBank &&
        this.getUser.office_type !== 'kantor_pelayanan'
      ) {
        return 'Pembayaran selain transfer kp dilakukan oleh kp';
      }

      return true;
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      updatePaymentMethod: 'payment_methods/updatePaymentMethod',
      deletePaymentMethod: 'payment_methods/deletePaymentMethod',
      softDeletePaymentMethod: 'payment_methods/softDeletePaymentMethod',
    }),
    getRelationships(resource, id) {
      let data = resource.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    async verifyPaymentMethod(paymentMethod) {
      const transferDateEl = document.getElementById('transfer-date');

      if (transferDateEl && !this.forms.transfer_date) {
        transferDateEl?.focus();
        this.errors.transfer_date = 'Tanggal wajib diisi';
        return;
      }

      // update payment method dgn memasukkan catatan dan tanggal,
      // habis itu verify payment
      await this.updatePaymentMethod({
        paymentMethodId: paymentMethod.id,
        attributes: {
          transfer_date: this.forms.transfer_date
            ? dayjs(this.forms.transfer_date).format()
            : undefined,
          description: this.forms.note,
        },
      });
      // verify payment
      this.$emit('verify-payment-method', paymentMethod);
    },
    remove() {
      // this.deletePaymentMethod({
      //   paymentMethodId: this.paymentMethod.id
      // });
      this.softDeletePaymentMethod({
        paymentMethodId: this.paymentMethod.id,
      });
      this.$emit('refetch');
    },
  },
};
</script>

<!-- eslint-disable vue/valid-template-root -->
<template>
  <tr class="bg-gray-50">
    <td
      class="flex items-start whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-900"
    >
      <div
        v-if="
          withNote &&
          !paymentMethod.attributes.is_verified &&
          !paymentMethod.attributes.description
        "
      >
        <base-input
          tag="text"
          label="Catatan"
          placeholder="Masukkan Catatan"
          id="notes"
          :classes="{
            input: 'p-4',
          }"
          v-model="forms.note"
          :invalid="!!errors.note"
          :message="errors.note"
          with-label
          fullwidth
        />
      </div>
      <p
        class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
        v-else-if="paymentMethod.attributes.description"
      >
        {{ paymentMethod.attributes.description }}
      </p>
    </td>
    <template v-if="invoice">
      <td class="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
        {{ paymentMethodName }}
      </td>
      <td
        v-if="getTransferDate"
        class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
      >
        {{ dayjs(getTransferDate).format('ll LT') }}
      </td>
      <td
        v-else-if="withDate && canVerify"
        class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
      >
        <base-input
          with-label
          label="Tanggal"
          type="datetime-local"
          id="transfer-date"
          :invalid="!!errors.transfer_date"
          :message="errors.transfer_date"
          v-model="forms.transfer_date"
        />
      </td>
      <td v-else class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"></td>
    </template>
    <template v-else>
      <td class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"></td>
      <td class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"></td>
    </template>
    <td
      v-if="!invoice"
      class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
    >
      {{ paymentMethodName }}
    </td>
    <td
      class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
      :class="{ 'text-right': paymentAmountAlignRight }"
    >
      {{ paymentMethod.attributes.payment_amount | toCurrency }}
    </td>
    <td
      :title="
        paymentMethod.attributes.is_verified
          ? 'Pembayaran telah diverifikasi oleh kasir'
          : null
      "
      v-if="withVerification"
      class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
    >
      <template v-if="paymentMethod.attributes.is_verified">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="mx-auto h-6 text-green-500"
          title="Metode pembayaran telah diverifikasi"
        >
          <path
            fill-rule="evenodd"
            d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </template>
      <template v-else-if="!canVerify">
        <svg
          :title="cannotVerifyMessage"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="mx-auto h-6 w-6 text-yellow-500"
        >
          <path
            fill-rule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clip-rule="evenodd"
          />
        </svg>
      </template>
      <button
        v-else
        @click="verifyPaymentMethod(paymentMethod)"
        class="mx-auto block rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg disabled:cursor-pointer disabled:bg-gray-300 disabled:hover:shadow-none"
      >
        Verifikasi
      </button>
    </td>
    <td
      v-if="!paymentMethod.attributes.is_verified"
      class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
    >
      <button
        type="button"
        @click="remove"
        class="mx-auto cursor-pointer rounded-full text-red-500 hover:bg-gray-100"
      >
        <Icon class="h-5 w-5" icon="heroicons:trash" />
      </button>
    </td>
    <td v-else></td>
  </tr>
</template>
